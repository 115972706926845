exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-slug-js": () => import("./../../../src/pages/{Page.slug}.js" /* webpackChunkName: "component---src-pages-page-slug-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-event-index-js": () => import("./../../../src/templates/event-index.js" /* webpackChunkName: "component---src-templates-event-index-js" */),
  "component---src-templates-event-post-js": () => import("./../../../src/templates/event-post.js" /* webpackChunkName: "component---src-templates-event-post-js" */),
  "slice---src-components-layout-footer-js": () => import("./../../../src/components/layout/footer.js" /* webpackChunkName: "slice---src-components-layout-footer-js" */),
  "slice---src-components-layout-header-js": () => import("./../../../src/components/layout/header.js" /* webpackChunkName: "slice---src-components-layout-header-js" */)
}

